<template>
  <div class="about">
    <div
      class="row blocks align-items-center justify-content-center"
      v-for="(block, index) in blocks"
      :class="[index % 2 !== 0 ? 'reversed' : '']"
      :key="index"
    >
      <div data-aos="fade-up" class="col-sm-12 col-md-6 block-img">
        <img
          :src="require(`../assets/img/about-img/${index + 1}.svg`)"
          alt=""
        />
      </div>
      <div
        data-aos="fade-down"
        class="col-sm-12 col-md-6"
        v-html="block.text"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title:
        "Edays - Développement personnel intensifier - comment améliorer sa qualité de vie",
      meta: [
        {
          name: "title",
          content:
            "Edays - Développement personnel intensifier - comment améliorer sa qualité de vie",
        },
        {
          name: "description",
          content:
            "Edays vous aide à améliorer vos performances et attendre vos objectifs",
        },
        {
          property: "og:title",
          content:
            "Edays - Développement personnel intensifier - comment améliorer sa qualité de vie",
        },
      ],
    };
  },
  data() {
    return {
      blocks: [
        {
          text: `
            <h2>À propos de nous</h2>
            <p>
              Notre but principal est avant tout de vous motiver chaque jour. <br /><br />
              À l’aide de phrases et de citations de grands philosophes, entrepreneurs, hommes d’État, écrivains…  que vous recevrez sur votre smartphone dans la matinée, l’après-midi et le soir, à votre guise.<br /><br />
              En dehors de la motivation, les phrases ont vocation de vous pousser à l’action, à réfléchir et à vous développer.
            </p>
          `,
        },
        {
          text: `
            <p>
              Prochainement, Edays mettra à votre disposition des conférences et des podcasts en ligne avec des personnalités, des conférenciers, connus et/ou en voie de l’être, dans le domaine du développement personnel, de la spiritualité, du bien-être, de l’entreprenariat etc
              Tout ça à l’aide de la réalité virtuelle et augmentée.
            </p>
          `,
        },
        {
          text: `
            <h3>Pourquoi les phrases et les citations?</h3>
            <p>
              Dans le contexte, elles sont plus efficaces que les vidéos de motivation, les livres audio ou les speechs, car on a tendance à trop en dire, à s’éparpiller alors que les phrases sont bien ciblées et vous permettent de mener la réflexion sur l’essentiel.
              Nous sommes convaincus qu’une phrase peut tout changer, alors imaginez une ou plusieurs phrases chaque jour.
              <br /><br />
              On ne vous conseille pas de ne pas lire ou de ne pas vous développer, tout au contraire, on vous encourage durant votre voyage à vous améliorer chaque jour à l’aide de la lecture, l’apprentissage et l’expérience.
            </p>
          `,
        },
        {
          text: `
            <h3>Pourquoi payer alors qu’on peut en trouver gratuitement?</h3>
            <p>
              Souvent on ne pense pas où on n’a pas le temps d’effectuer les recherches ou d’aller chercher des phrases et des citations, et même quand on s’y met, on est obligé d’en lire une vingtaine avant de tomber sur celles qui nous inspirent vraiment et même là ce n’est pas gagné car on est fréquemment déconcentré par d’autres phrases, la publicité ou d’autres contenus notamment sur les réseaux sociaux.
              Tout ça diminue l’efficacité à son minimum.<br /><br />
              Edays vous envoie les phrases et les citations sélect directement sur votre téléphone sans pubs ou autre chose qui pourrait vous déconcentrer.
            </p>
          `,
        },
        {
          text: `
            <p>
              Au-delà de l’aspect commercial, Edays a dans ses objectifs des thèmes sociaux écologiques et économiques.
              Dans un premier temps nous allons utiliser une partie des résultats générés par la plate-forme pour replanter des arbres et veiller à leur environnement dans les forêts victimes de feu où de déforestation.<br /><br />
              Donc en rejoignant la famille Edays vous agissez déjà!!!<br /><br />
              Par la suite, avec le développement de la plate-forme nous aimerions agir dans les domaines tels que l’éducation (apprentissage), l’énergie verte, l’alimentation, le transport etc<br /><br />
              Et bien sûr vous serez tenus au courant de tout!
            </p>
          `,
        },
      ],
    };
  },
};
</script>
